<template>
  <v-card elevation="0">
    <v-card
      @click="handlePay"
      class="d-flex align-center justify-center mx-auto py-2"
      :color="statusColor"
      dark
    >
      <v-icon class="text-h4 mr-2">{{ statusIcon }}</v-icon>
      <div class="text-h6">{{ statusText }}</div>
    </v-card>
    <v-card-subtitle>
      <div>商品の小計： {{ dataSource.original_amount || 0 }} 円</div>
      <div>クーポン割引： {{ dataSource.coupon_amount || 0 }} 円</div>
      <div>消費ポイント： {{ dataSource.point || 0 }} 円</div>
      <div>ご請求額: {{ dataSource.total_amount || 0 }} 円</div>

      <div v-if="dataSource.status === 0">
        支払期限:
        {{ dataSource.expires_at && $dayjs(dataSource.expires_at).format('YYYY-MM-DD HH:mm') }}
      </div>
      <div v-else-if="dataSource.status === 1">
        支払時間: {{ $dayjs(dataSource.date_paid).format('YYYY-MM-DD HH:mm') }}
      </div>
      <div class="text-decoration-line-through" v-else>
        支払期限:
        {{ dataSource.expires_at && $dayjs(dataSource.expires_at).format('YYYY-MM-DD HH:mm') }}
      </div>
    </v-card-subtitle>

    <!-- <v-card-actions>
      <v-btn color="orange lighten-2" text>注文を見る</v-btn>

      <v-spacer></v-spacer>

      <v-btn icon @click="show = !show">
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions> -->

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <v-list dense v-for="order in dataSource.orders" v-bind:key="order._id">
          <OrderItem :order="order" />
        </v-list>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
  import OrderItem from './OrderItem.vue';

  export default {
    name: 'PaymentCard',

    props: {
      isReturn: Boolean,
      dataSource: Object,
    },

    data: () => ({
      show: true,
    }),

    components: {
      OrderItem,
    },

    computed: {
      paymentMethod() {
        return ['PayPay決済', 'クレジット決済'][this.dataSource.payment_method];
      },
      statusText() {
        return ['支払い待ち', '支払い済', 'キャンセル', '返金済み', '期限切れ'][
          this.dataSource.status
        ];
      },
      statusColor() {
        return ['orange', 'green', 'grey', 'red', 'grey'][this.dataSource.status];
      },
      statusIcon() {
        return [
          'mdi-credit-card-sync-outline',
          'mdi-credit-card-check-outline',
          'mdi-credit-card-off-outline',
          'mdi-credit-card-refund-outline',
          'mdi-credit-card-off-outline',
        ][this.dataSource.status];
      },
    },

    methods: {
      handlePay() {
        if (this.isReturn) {
          this.$router.push('/mypage/return-order-history/' + this.dataSource._id);
        } else {
          this.$router.push('/mypage/order-history/' + this.dataSource._id);
        }
      },
    },
  };
</script>
<style>
  .payment-status {
    justify-content: center;
  }
</style>
